"use client";

import React, { useEffect, useState } from "react";

import moment from "moment/moment";

import { Box, ThemeProvider, Typography } from "@mui/material";

import ViewTextBox from "@/components/Common/ViewTextBox";
import { theme } from "@/components/theme";
import Navbar from "../Navbar";
import { getSingleSeoPageData } from "@/lib/SeoPageAPI";

function SingleSeoPageDetailPage({ params }) {
  const [singleSeoData, setSingleSeoData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSingleSeoPageData(
        params.slug,
        window.location.hostname
      );
      setSingleSeoData(data);
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Box sx={{ color: "#3A3A3A" }}>
        <Box
          sx={{
            m: { lg: "140px 100px", xs: "100px 20px" },
          }}
        >
          <Box
            sx={{
              position: "relative",
              padding: { lg: "200px 80px", xs: "60px 80px" },
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: singleSeoData?.response?.featuredImage
                  ? `url('${singleSeoData?.response?.featuredImage}')`
                  : "url('/assets/images/rec.png')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "100%",
                backgroundSize: "contain",
                zIndex: 1,
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,

                  zIndex: -1,
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              marginTop: 5,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { lg: "46px", xs: "26px" },
                fontWeight: 700,

                width: { lg: "100%", xs: "100%" },
              }}
            >
              {singleSeoData?.response?.seoPagesTitle}
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#3A3A3A",
                }}
              >
                {singleSeoData?.response?.seoPagesCreatedDate &&
                  moment(singleSeoData?.response?.seoPagesCreatedDate).format(
                    "LL"
                  )}
              </Typography>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "30px",
            }}
          >
            {singleSeoData?.response?.seoPagesTags
              ?.split(",")
              .map((tag, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      p: "5px 10px",
                      backgroundColor: `#E9326B20`,
                      color: "black",
                      mr: "8px",
                    }}
                  >
                    {tag}
                  </Box>
                );
              })}
          </Box>

          <Box
            sx={{
              mt: { lg: 4, xs: 4 },
              ml: { lg: 15, xs: 4 },
              mr: { lg: 30, xs: 4 },
              color: "#3A3A3A",
            }}
          >
            <ViewTextBox
              value={singleSeoData?.response?.seoPagesDescription}
            ></ViewTextBox>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SingleSeoPageDetailPage;
