//"use server";
const API = process.env.NEXT_PUBLIC_API_URL;
import getHeaders from "./APIHeaders";

export async function getSingleSeoPageData(title, hostname) {
  const headers = await getHeaders(hostname);
  const res = await fetch(
    API + `/seo-pages/${title}`,
    {
      headers,
    },
    { cache: "force-cache" }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}

export async function getSeoData(hostname) {
  const headers = await getHeaders(hostname);
  const res = await fetch(
    API + `/seo-pages?page=0&size=3`,
    {
      headers,
    },
    { cache: "force-cache" }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}

export async function getAllSeoPage(hostname) {
  const headers = await getHeaders(hostname);
  const res = await fetch(API + `/seo-pages?page=0`, {
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
